<template>
  <base-section id="we-help-your-success">
    <v-container>
      <v-row align="start">
        <v-col
          class="fluid pa-0 ma-0"
          cols="12"
          md="12"
        >
          <base-section-heading
            title="Meet the Team"
            icon="fal fa-users"
            outlined
          />
          <base-body
            class="text-justify ma-4"
          >
            Our founding team of four brings together a strong combination of biochemistry, pharmacy, bioorganic chemistry and business development expertise. Shared years of practical experience in the environment of industrial production and application of nucleic acids and proteins have sharpened our problem awareness and brought us together as Team Aptamimetics. Together, we developed AGE-SELEX, our patent-pending aptamer development technology. With these fundamentals in place, we are ready to push the current boundaries in the production of biopharmaceuticals and advanced cell culture products.
          </base-body>
        </v-col>
      </v-row>
      <v-row align="stretch">
        <v-col
          v-for="(person,n) of team"
          :key="n"
          class="d-flex justify-center align-top"
          cols="12"
          md="3"
        >
          <v-card
            flat
          >
            <v-sheet
              class="px-0 mx-1"
              outlined
            >
              <v-img
                :max-height="$vuetify.breakpoint.mdAndUp ? 500 : 420"
                :src="require(`@/assets/${person.img}`)"
                color="white"
                flat
                class="mt-0 mx-0"
                cover
              />
            </v-sheet>

            <v-sheet
              class=" px-4 py-1 mx-1 grey-lighten-2--text"
              color="secondary"
              style="opacity: 0.90;"
              justify="end"
            >
              <base-subheading
                :title="person.name"
                class="white--text mb-0"
                color="secondary"
              />
            </v-sheet>

            <v-sheet
              class="px-4 pa-1 mx-1 "
              outlined
            >
              <base-body class="text-xs-body-3 text-md-body-1">
                <div class="font-weight-bold secondarty--text text--lighten-1 mb-1">
                  {{ person.title }}
                </div>
                <span class="text-justify">{{ person.text }}</span>
              </base-body>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWeHelpYourSuccess',
    data: () => ({
      team: [{
               name: 'Dr. Jan Castonguay',
               img: 'team/AvatarJC.jpg',
               title: 'The Biochemist.',
               text: 'Jan\'s scientific focus is bioorganic chemistry and protein biochemistry. He is constantly developing new and creative methods to create the best possible aptamer products. He also brings professional experience in the regulation of biological raw materials to the team.',
               mail: 'jan.castonguay@aptamimetics.com',
             },
             {
               name: 'Dr. Peter Siegert',
               img: 'team/AvatarPS.jpg',
               title: 'The Pharmacist.',
               text: 'Peter is working on cellular signal transduction, especially in the context of cell differentiation. He always keeps the patient in mind when it comes to our products. In addition, he has experience in process management and its digitalization and currently leads the day-to-day business.',
               mail: 'peter.siegert@aptamimetics.com',
             }, {
               name: 'Dr. Jan Zimmermann',
               img: 'team/AvatarJZ.jpg',
               title: 'The Chemist.',
               text: 'Jan has been working in the field of nucleic acid chemistry for more than two decades. He has expertise covering all aspects of industrial production and development of biotech and nucleic acid products. In addition, the team benefits from his strong network in academia and industry.',
               mail: 'jan.zimmermann@aptamimetics.com',
             },
             {
               name: 'Christoph Stöver',
               img: 'team/AvatarCS.jpg',
               title: 'The Physicist and Economist',
               text: 'Christoph specializes in leading strategic business and project funding. With his technical background in microfluidics, he also provides valuable insights for our process development. With his strong network and experience, he drives our business development and project work.',
               mail: 'christoph.stoever@aptamimetics.com',
             },
      ],
    }),

  }
</script>
